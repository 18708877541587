import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App)

// 路由
import router from './router/index.js'

// 国际化
import i18n from "./locales/i18n.js"
const { t } = i18n.global;
app.config.globalProperties.$t = t; // i18l方法 注册为全局方法

// element-plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ELIcons from '@element-plus/icons-vue'
for (let iconName in ELIcons) {
  app.component(iconName, ELIcons[iconName])
}

import * as globalComponents from './components/registrationComponent.js';
Object.keys(globalComponents).forEach(key => {
  const component = globalComponents[key];
  app.component(key, component);
});

app.use(router); //注册路由
app.use(i18n); //注册国际化
app.use(ElementPlus) //注册element-plus
app.mount('#app');
