<template>
  <div class="footers">
    <div class="footerTop">
      <div class="footerTopLeft">
        <img src="../assets/logi.png" class="logClass">
      </div>
      <div class="footerTopContent">
        <div v-for="(item,index) in menusList" :key="index">
          <span class="title">{{ item.title }}</span>
        </div>
      </div>
      <div class="footerTopRight">
        <div v-for="(item,index) in menusImage" :key="index" class="menusItem" @click="menusImaegClick(item)">
          <img :src="item.image" alt="" class="menusImageItem">
        </div>
      </div>
    </div>
    <div class="footerContent">
      <div class="contactInformationClass">
        <div class="titel">{{ $t("contactInformation") + ':' }}&nbsp;</div>
        <div class="text">88002500810</div>
      </div>
      <div class="addressClass">
        <div class="titel">{{ $t("address") + ':' }}&nbsp;</div>
        <div class="text"> {{ $t("addressChina") }}&nbsp;;&nbsp;</div>
        <div class="text">{{ $t("addressRussian") }}</div>
      </div>
    </div>
    <div class="footerBottom">
      {{ $t("confidentialityPolicy") }}
    </div>
  </div>
</template>
  
  
<script setup>
import { ref, reactive, getCurrentInstance, onMounted } from "vue";
import whatsapp from "../assets/whatsapp.png";
import telegram from "../assets/telegram.png";
import vk from "../assets/vk.png";
import youtube from "../assets/youtube.png";
import facebook from "../assets/facebook.png";
import instagram from "../assets/instagram.png";
import yandex from "../assets/yandex.png";
import tiktok from "../assets/tiktok.svg"
const $t = getCurrentInstance().appContext.config.globalProperties.$t;//引入语言

let menusList = reactive([//导航栏
  {
    title : $t("privacyPolicy"),
  },
  {
    title : $t("userAgreement"),
  },
  {
    title : $t("aboutMEILIN"),
  },
  {
    title : $t("dealerWebsite"),
  },
  {
    title : $t("newsCenter"),
  },
  {
    title : $t("appDownload"),
  },
]);

let menusImage = reactive([
  {
    image: whatsapp,
    url: "https://api.whatsapp.com/send/?phone=79939072477&text&type=phone_number&app_absent=0",
  },
  {
    image: telegram,
    url: "https://t.me/meilin_auto",
  },
  {
    image: vk,
    url: "https://vk.com/meilinauto",
  },
  {
    image: youtube,
    url: "https://www.youtube.com/@meilinauto",
  },
  {
    image: tiktok,
    url: "https://www.tiktok.com/@meilinauto?is_from_webapp=1&sender_device=pc",
  },
  {
    image: facebook,
    url: "",
  },
  {
    image: instagram,
    url: "https://www.instagram.com/meilin_auto?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
  },
  {
    image: yandex,
    url: "https://dzen.ru/meilin",
  },
])

const menusImaegClick = (item) => {
  if(item.url){
    window.open(item.url)
  }
}

</script>
  
  
<style lang="scss" scoped>
.footers{
  background-color: #212121;
  padding-bottom: 20px;
  min-width: 1200px;
  .footerTop{ 
    padding: 18px 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #474444;
    .footerTopLeft{
      .logClass{
        width: 128px;
        height: 33px;
      }
    }
    .footerTopContent{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      margin: 0 10%;
      font-size: 14px;
      font-weight: 500;
      color: #D3D3D3;
      .title{
        cursor: pointer;
      }
    }
    .footerTopRight{
      display: flex;
      .menusItem{
        margin: 0 5px;
        .menusImageItem{
          width: 26px;
          height: 26px;
          cursor: pointer;
        }
      }
    }
  }
  .footerContent{
    padding: 30px 5%;
    display: flex;
    .contactInformationClass,.addressClass{
      display: flex;
      .titel{
        font-size: 10px;
        font-weight: 400;
        color: #D3D3D3;
        width: fit-content;
      }
      .text{
        font-size: 10px;
        font-weight: 400;
        color: #7A7A7A;
      }
    }
    .addressClass{
      margin-left: 100px;
    }
  }
  .footerBottom{
    font-size: 10px;
    font-weight: normal;
    color: #D3D3D3;
    text-align: center;
  }
}
</style>
  